<template>
  <div>
    <div class="card-title mb-3">Produttori (da mercato)</div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="producer.role.name"
            headerText="Produttore"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.country"
            headerText="Nazione"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.tel"
            headerText="Telefono"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.url"
            headerText="Url"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.info_contact"
            headerText="Contatti"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.desc_zone"
            headerText="Zona servita"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="member_from"
            headerText="Attivo dal"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="status"
            headerText="Stato"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="is_active"
            headerText="Attivo"
            displayAsCheckBox="true"
            type="boolean"
            editType="booleanedit"
          ></e-column>
          <!-- <e-column
            field="status"
            headerText="Attivo"
            displayAsCheckBox="true"
            type="boolean"
            editType="booleanedit"
          ></e-column>
          <e-column
            field="permissions.role_dc"
            headerText="Dati e contatti"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column>
          <e-column
            field="permissions.role_pp"
            headerText="Prodotti e prezzi"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column>
          <e-column
            field="permissions.role_lg"
            headerText="Logistica"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column>
          <e-column
            field="permissions.role_os"
            headerText="Ordini"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column>
          <e-column
            field="permissions.role_fin"
            headerText="Contabilità"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column>
          <e-column
            field="permissions.role_exp"
            headerText="Esportazioni"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="permissionsData"
            foreignKeyField="value"
            defaultValue=1
          ></e-column> -->
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";
import TB from "../../../common/TB";
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_marketproducer_connections", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            // "user.first_name",
            // "user.last_name",
            "id",
          ],
        },
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        // toolbar: ["Add","Edit", "Update", "Cancel"],
        toolbarOptions: [
          // {
          //   text: "Refresh",
          //   tooltipText: "Refresh data",
          //   prefixIcon: "e-expand",
          //   id: "refresh",
          // },
          // "Add",
          // "Edit",
          // // "Delete",
          // "Update",
          // "Cancel",
          "Search",
          "ColumnChooser",
        ],
        // reload_on_save:true,
      },
      permissionsData: new DataManager([
        { value: 0, label: "nessuno" },
        { value: 1, label: "lettura" },
        { value: 2, label: "lettura e scrittura" },
      ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
